import {
  TOGGLE_DISABLE_BUTTON,
  TOGGLE_MENU_BAR,
  TOGGLE_DOMAIN_CONTROL_MENU
} from "./types";

export const toggleDisableButton = disable => dispatch =>
  dispatch({
    type: TOGGLE_DISABLE_BUTTON,
    disable
  });

export const toggleMenuBar = payload => dispatch =>
  dispatch({
    type: TOGGLE_MENU_BAR,
    payload
  });

export const toggleDomainControl = payload => dispatch =>
  dispatch({
    type: TOGGLE_DOMAIN_CONTROL_MENU,
    payload
  });